<template>
  <main class="page-container" id="sme-apply">
    <common-list :div-class="['page-component']" :is-loading="isLoading" :is-no-result="isNoResult">
      <!-- 지자체 지원사업 안내 -->
      <div>
        <div class="apply-header">
          <!-- 메뉴이동 로직 추가 S (20220714 - hib)  -->
          <div v-if="isMobile" class="box_select">
            <button type="button" id="select_btn" @click="click">지자체</button>
              <ul id="select_list">
                <li v-for="(item, idx) in menus[0].children" :key="idx"><a :href="item.path">- {{item.title}}</a></li>
              </ul>
          </div>
          <!-- 메뉴이동 로직 추가 E (20220714 - hib)  -->

          <!-- <div v-if="isMobile" class="section-notice-arrow">
            <a  href="/support" class="btn-arrow">
              <i class="icon-arrow-prev">이전 페이지</i>
            </a>
            <button class="btn-arrow disabled">
              <i class="icon-arrow-next">다음 페이지</i>
            </button>
          </div> -->
          <h4 class="title">{{new Date().getFullYear()}}년 지자체 지원사업 안내</h4>
          <div class="btn_search">
            <input v-model="sprtBizNm" type="text" class="form_search" placeholder="사업명을 입력해 주세요" @keyup.enter="getSupportBusinessList" style="height: 50px">
            <button class="ico_search" @click="getSupportBusinessList">검색</button>
          </div>
        </div>
        <div class="support-title">
          <p class="support-title-desc">
            소상공인의 온라인 유통판로를 지원하는 지역자치 단체의 사업들을 담은 공간입니다.
          </p>
        </div>
        <br/>
        <div class="second_wrap">
          <ul v-if="isMobile" class="tab_wrap" id="tab_wrap">
            <li v-for="(option, idx) in tempCode" :key="idx" style="width: 100%">
              <button type="button" v-bind:class="[ laeTyp===option.cmmnCdVal? 'tab_list on' : 'tab_list']" @click="changeLaeTyp(option.cmmnCdVal,option.cmmnCdValNm)">{{ option.cmmnCdValNm }}</button>
            </li>
          </ul>
          <ul v-else class="tab_wrap" id="tab_wrap">
            <li v-for="(option, idx) in laeTypCdOptions" :key="idx">
              <button type="button" v-bind:class="[ laeTyp===option.cmmnCdVal? 'tab_list on' : 'tab_list']" @click="changeLaeTyp(option.cmmnCdVal,option.cmmnCdValNm)">{{ option.cmmnCdValNm }}</button>
            </li>
          </ul>

          <div v-if="isMobile" class="accordion">
            <input type="checkbox" id="menu"/>

            <label class="lae" for="menu" @click="btnClick">
              <span>펼쳐보기</span>
            </label>
            <ul class="tab_wrap">
              <li v-for="(option, idx) in laeTypCdOptions" :key="idx">
                <button type="button" v-bind:class="[ laeTyp===option.cmmnCdVal? 'tab_list on' : 'tab_list']" @click="changeLaeTyp(option.cmmnCdVal,option.cmmnCdValNm)">{{ option.cmmnCdValNm }}</button>
              </li>
            </ul>
          </div>
          <div class="tbl_wrap">
            <table>
              <caption>지자체 지원사업의 지역명,사업연도,사업명,상세보기 제공</caption>
              <colgroup>
                <col v-if="!isMobile" width="15%">
                <col v-if="!isMobile" width="10%">
                <col width="*">
                <col v-if="!isMobile" width="15%">
              </colgroup>
              <thead>
              <tr>
                <th v-if="!isMobile">지역명</th>
                <th v-if="!isMobile">사업연도</th>
                <th>사업명</th>
                <th v-if="!isMobile">상세보기</th>
              </tr>
              </thead>
              <tbody>
              
              <tr v-for="(item, idx) in items" class="apply-list-item" :key="idx">
              <!-- <tr v-for="(item, idx) in items" class="apply-list-item" :key="idx" :style="(!isMobile) ? '' : noneBlock(item.aplyEndYmd)"> -->
                <td v-if=" !isMobile && (idx === 0 || item.laeTyp !== items[idx-1].laeTyp)" :rowSpan="getRowSpan(items, item)">
                  {{item.laeNm}}
                </td>
                <td v-if="!isMobile" >{{ item.sprtBizYr }}년</td>
                <td v-if="!isMobile" class="title">
                  <span>{{ item.sprtBizNm }}</span>
                </td>

                <td v-if="isMobile" class="title" :style="(!isMobile) ? '' : backgroundColor(item.aplyEndYmd)">
                  <a :href="item.aplyUrl" title="상세보기" target="_blank" style="pointer-events:auto">
                    <div>
                      <span v-if="aplyEndDateSet(item.aplyEndYmd) >= nowDateSet()">{{ item.sprtBizNm }}</span>
                      <span v-if="aplyEndDateSet(item.aplyEndYmd) < nowDateSet()">(모집마감){{ item.sprtBizNm }}</span>
                    </div>
                  </a>
                </td>
                <td v-if="!isMobile" >
                  <a v-if="aplyEndDateSet(item.aplyEndYmd) >= nowDateSet()" :href="item.aplyUrl" title="상세보기" class="btn-secondary btn-detail" target="_blank" style="pointer-events: auto">상세보기</a>
                  <a v-if="aplyEndDateSet(item.aplyEndYmd) < nowDateSet()" :href="item.aplyUrl" title="모집마감" class="btn-secondary-close btn-detail" target="_blank" style="pointer-events: auto">모집마감</a>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </common-list>
  </main>
</template>

<script>
import {ACT_GET_SHOP_MALL_COMMON_CODE_LIST, ACT_GET_SUPPORT_BUSINESS_LIST} from '../../../store/_act_consts';
import {getCheckItems, getItems, isSuccess, lengthCheck} from '../../../assets/js/utils';
import { MUT_SET_COMMON_CODE_ITEMS } from "../../../store/_mut_consts";
import CommonList from '../../../components/common/CommonList';
import {mapGetters} from "vuex";
import menus from '../../../assets/js/menus';

export default {
  name: 'Support',
  components: {
    CommonList
  },
  data: () => ({
    isLoading: false,
    bizCtgrDcd:'1021003',
    laeTyp:'',
    laeTypNm:'전국',
    sortCd: '',
    sprtBizNm: '',
    items: [],
    tempCode:[{cmmnCd:'LOCAL_TYP_CD', cmmnCdVal:'', cmmnCdValNm:'전국'}],
    menus: menus.filter(x => (x.code === 'support')), //모바일 중메뉴 데이터 (20220714 - hib)
  }),
  watch: {
    laeTyp(){
      this.getSupportBusinessList(true);
    }
  },
  computed: {
    ...mapGetters("common", ["isMobile", "commonCode"]),
    laeTypCdOptions() {
      this.commonCode.unshift({cmmnCd:'LOCAL_TYP_CD', cmmnCdVal:'', cmmnCdValNm:'전국'})
      return this.commonCode.filter((x) => x.cmmnCd === "LOCAL_TYP_CD");
    },
    isNoResult(){
      return this.items.length === 0;
    },
  },
  created() {
    this.getCommonCdList();
    this.getSupportBusinessList(true);
  },
  methods: {
    getSupportBusinessList(isInit){
      if(isInit) {
        this.items = [];
        this.isLoading = true;
      }
      this.$store.dispatch(`support/${ACT_GET_SUPPORT_BUSINESS_LIST}`, {
        sortCd: this.sortCd,
        sprtBizNm: this.sprtBizNm,
        bizCtgrDcd: this.bizCtgrDcd,
        laeTyp: this.laeTyp
      }).then(res => {
        if(lengthCheck(res)){
          this.items = getItems(res);
        }
        this.isLoading = false;
      }).catch(e => {
        console.error(e);
        this.isLoading = false;
      })
    },
    getCommonCdList() {
      this.$store.dispatch(`common/${ACT_GET_SHOP_MALL_COMMON_CODE_LIST}`, {}).then((res) => {
        this.$store.commit(`common/${MUT_SET_COMMON_CODE_ITEMS}`, getCheckItems(res));
      });
    },
    changeLaeTyp(laeTypCd,laeTypNm){
      this.laeTyp = laeTypCd;
      this.laeTypNm = laeTypNm;
    },
    btnClick() {
      let element = document.getElementById('tab_wrap');
      element.classList.toggle('show_menu');
    },
    getRowSpan(arr, data) {
      let rowSpan = 0;
      arr.forEach(item => {
        if(item.laeTyp === data.laeTyp) rowSpan++;
      });

      return rowSpan;
    },
    /* 중메뉴이동 (20220714 - hib) */
    click() {
      let element = document.getElementById('select_list');
      element.classList.toggle('show_list');
      let element02 = document.getElementById('select_btn');
      element02.classList.toggle('active');
    },
    //노출종료일자 데이터 세팅(20220824 hib)
    aplyEndDateSet(aplyEndYmd){
      return aplyEndYmd.replace('-', '').replace('-', '');
    },
    //현재날짜 데이터 세팅(20220824 hib)
    nowDateSet(){
      const current = new Date();                 //현재날짜 가져오기
      this.currentDay = current.getDate();        //현재일자
      this.currentMonth = current.getMonth() + 1; //현재달
      this.currentYear = current.getFullYear();   //현재년도
      //일자/달 이 10일보다 작을경우 0을 붙여준다.
      return `${this.currentYear}${this.currentMonth < 10 ?  `0${this.currentMonth}`: this.currentMonth}${this.currentDay < 10 ? `0${this.currentDay}`: this.currentDay}`
    },
    //모바일일경우 노출기간이 지났을때 데이터 삭제 - 사용안함(20220824 hib)
    noneBlock(aplyEndYmd){
      let style = '';
      const aplyEndDate = this.aplyEndDateSet(aplyEndYmd);
      const nowDate = this.nowDateSet();
      if(aplyEndDate >= nowDate){
        style = true;
      }else if(aplyEndDate < nowDate){
        style = false;
      }
      return `display : ${style ? 'block;' : 'none'}`;
    },
    //모바일일경우 노출기간이 지났을때 백그라운드 컬러 변경(20220824 hib)
    backgroundColor(aplyEndYmd){
      let style = '';
      const aplyEndDate = this.aplyEndDateSet(aplyEndYmd);
      const nowDate = this.nowDateSet();
      if(aplyEndDate >= nowDate){
        return "";
      }else if(aplyEndDate < nowDate){
        return "background-color : #cccccc;";
      }
    },
  },
};
</script>
